<template>
    <v-row>
        <v-col cols="12" class="mt-2">

            <Wallet :cash.sync="history_data.cash"
                    :position.sync="position"
            >
            </Wallet>

        </v-col>

        <v-divider class="ml-1 mr-1"></v-divider>

        <v-col cols="12" class="mt-2">

            <v-simple-table>
                <tbody>
                <tr v-for="(item, i) in history_data.datas"
                    :key="i"
                >
                    <td class="pr-0 text-center">
                        <span class="table_font">{{ item.create_date }}</span><br>
                        <span class="table_font grey--text">{{ item.create_time }}</span>
                    </td>
                    <td>
                        <span class="table_font">{{ item.product_name }}</span>
                    </td>
                    <td class="pl-0 text-center">
                        <span class="table_font">{{ formatPrice(item.product_price) }}</span><br>
                        <span class="table_font" :class="item.type=='구매'? 'buy_color':'sale_color'">{{ item.type }}</span>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>

        </v-col>

        <v-overlay :value="loading_bar">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-row>
</template>

<script>
    import axios from '../../../service/axios'

    export default {
        name: 'History',
        data(){
            return {
                history_data: {
                    cash: null,
                    datas:[]
                },
                position:'text-center',
                loading_bar: false,
            }
        },
        components: {
            Wallet: () => import("@/components/wallet/Wallet"),
        },
        created () {
            this.ownWalletInfo();
            this.historyInfo();
        },
        methods: {
            formatPrice(value) {
                try {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                }
                catch{
                    return value;
                }
            },
            async historyInfo(){
                const params = {
                    accessKey: this.$cookies.get('token')
                }
                await axios.post('/api/user/ownPaymentInfo', params).then(res =>{
                    this.loading_bar = false;
                    this.history_data.datas = res.data.data;
                }).catch(error=>{
                    this.loading_bar = false;
                    console.log('페스트 오류', error)
                });
            },
            async ownWalletInfo(){
                this.loading_bar = true;
                const params = {
                    accessKey: this.$cookies.get('token'),
                }
                await axios.post('/api/user/ownWalletInfo', params).then(res =>{
                    this.loading_bar = false;
                    this.history_data.cash = res.data.data.cash;
                }).catch(error=>{
                    this.loading_bar = false;
                    console.log('페스트 오류', error)
                });
            },
        },
    }

</script>

<style lang="scss">
    .table_font{
        font-size: 0.8rem !important;
        letter-spacing: -0.07em;
    }
    .sale_color{
        color: #ff0000;
    }
    .buy_color{
        color: #4caf50;
    }
</style>